import { useContext, useMemo } from "react"
import { FaStar, FaTruck } from "react-icons/fa"

import { AppContext } from "../../../../App"
import { Avatar, Divider, Span } from "../../../../c"
import { formatNumber, t } from "../../../../f"

import { DropdownEditMenu } from "../DropdownEditMenu"
import { FiMapPin } from "react-icons/fi"
import { BsThreeDotsVertical } from "react-icons/bs"

export function ContratoCard({ contrato, setEditing, setDetalhe, setEditCotas, get, recalcular }){
  const App = useContext(AppContext)
  const lang = useMemo(
    () => ({ ...App.lang.global, ...App.lang.contratos }),
    [App.lang.contratos, App.lang.global],
  )

  const DS_OBS = useMemo(() => {
    const {
      CD_STS,
      DS_OBS,
      DT_FRETEMOTORISTANEW,
      DT_FRETENEW,
      DT_MAXCOTA,
      ID_NATOP,
      show_recalc,
      VL_FRETEMOTORISTANEW,
      VL_FRETENEW,
    } = contrato
    const {
      card_data_insuficiente,
      card_nova_tarifa,
      card_pausar,
      card_valor_maximo_motorista,
    } = lang

    return [
      ...JSON.parse(DS_OBS ? DS_OBS : '[]'),
      !!VL_FRETENEW
        ? { id_color: 'danger', ds_obs: `${card_nova_tarifa + DT_FRETENEW}: R$ ${VL_FRETENEW }` } : null,
      !!VL_FRETEMOTORISTANEW
        ? { id_color: 'danger', ds_obs: card_valor_maximo_motorista + DT_FRETEMOTORISTANEW + ': R$ ' + VL_FRETEMOTORISTANEW } : null,
      CD_STS === "-2"
        ? { id_color: 'warning', ds_obs: card_pausar + DT_MAXCOTA } : null,
      show_recalc && ID_NATOP === '1'
        ? { id_color: 'danger', ds_obs: card_data_insuficiente } : null,
    ].filter(o => !!o && !!o.ds_obs)
  }, [contrato, lang])

  function displayValue(value) {
    return value ? value : 'N/A';
  }

  const parceiroOrigem = contrato.PARCEIRO_ORIGEM_REDUZIDO ?? contrato.PARCEIRO_ORIGEM;
  const parceiroDestino = contrato.PARCEIRO_DESTINO_REDUZIDO ?? contrato.PARCEIRO_DESTINO;

  return (
    <div className="contrato-card">
      <div className="header" style={{ margin: "-10px -10px 0" }}>
      <div
        className="f f-column g2 w100"
        style={{
          backgroundColor: "var(--colorTitle)",
          padding: "12px",
        }}
      >
        <div className="f">
          <div className="f g2 f-column w100">

            {/* Primeira linha - ID e Unidade */}
              <div className="f w100 g2 first-row">
                <div className="f f-column g1">
                  <span className="title-bold">ID</span>
                  <span className="content">{contrato.ID_CN}</span>
                </div>
                <div className="f f-column g1">
                  <span className="title-bold">{lang.unidade}</span>
                  <span className="content">
                    {contrato.ID_UORG + ' - ' + contrato.NM_PESUND}
                  </span>
                </div>
              </div>

              {/* Linhas seguintes - Origem, Destino, Produto e Cliente */}
              <div className="g2 subsequent-rows">
                <div className="f f-column g1 flex-1">
                  <div className="content w100 origem title-bold" title={t(lang, "parceiro_origem")}>
                    {parceiroOrigem}
                  </div>
                  <div className="content w100 origem" title={t(lang, "origem")}>
                    <FiMapPin size={10} className="margin-right"/>
                    {contrato.DS_ORIGEM}
                  </div>
                  <div className="content w100 origem title-bold" title={t(lang, "parceiro_destino")}>
                    {parceiroDestino}
                  </div>
                  <div className="content destino" title={t(lang, "destino")}>
                    <FiMapPin size={10} color="var(--success)" className="margin-right"/>
                    {contrato.DS_DESTINO}
                  </div>
                </div>

                <div className="f f-column g1 flex-1">
                  <div className="title-bold">{lang.produto}</div>
                  <div className="content">
                    {contrato.NM_PRO}
                  </div>
                </div>
                <div className="f f-column g1 flex-1">
                  <div className="title-bold">{lang.cliente}</div>
                  <div className="content">{contrato.CLIENTE}</div>
                </div>
              </div>
          </div>

          <div style={{marginTop: "2rem"}}>
            <Avatar size={48} className=""/>
          </div>
        </div>

        {/* Status e Ações */}
        <div
          className="status"
          style={{
            backgroundColor: contrato.CD_STS === '1' ? 'var(--success)' : 'var(--danger)',
            position: "absolute",
            right: "0",
            top: "0",
            padding: "8px 16px",
            boxSizing: "border-box",
          }}
        >
          <span className="f g1 center-v center-h">
            {contrato.CD_STS === '1' ? 'ATIVO' : 'ENCERRADO'}
            {App.moment().format('DD/MM/YYYY') === contrato.DT_CDT && (
              <div title={lang.novo}>
                <FaStar size={18}/>
              </div>
            )}
            <DropdownEditMenu
              recalcular={recalcular}
              setDetalhe={setDetalhe}
              contrato={contrato}
              setEditCotas={setEditCotas}
              setEditing={setEditing}
              control={<BsThreeDotsVertical size={18} />}
            />
          </span>
        </div>


      </div>
    </div>

    <div className="infos">
      {/* Bloco: Volume e Saldo */}
      <div className="info-block">
        <div className="f f-column g1">
          <span className="title-bold">{lang.volume}</span>
          <span className="content">{contrato.SLC}</span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.saldo}</span>
          <span className="content">{contrato.SALDO}</span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.inicio}</span>
          <span className="content">{contrato.DT_INICIO}</span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.fim}</span>
          <span className="content">{contrato.DT_MAXCOTA}</span>
        </div>
      </div>
      <Divider borderColor={true}/>

      {/* Bloco: Frete */}
      <div className="info-block">
        <div className="f f-column g1">
          <span className="title-bold">{lang.frete}</span>
          <div className="f g1">
            <span className="content">{formatNumber(contrato.VL_FRETE ?? 0, 2)}</span>
            <span title="Média" className="sub-content">(∅ {formatNumber(contrato.AVG_FRETE ?? 0, 2)})</span>
          </div>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.frete_motorista}</span>
          <div className="f g1">
            <span className="content">{formatNumber(contrato.VL_FRETEMOTORISTA ?? 0, 2)}</span>
            <span title="Média" className="sub-content">(∅ {formatNumber(contrato.AVG_FRETEMOTORISTA ?? 0, 2)})</span>
          </div>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.pedagio}</span>
          <span className="content">
            {displayValue(contrato.VL_PEDAGIO)}
          </span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.tomador}</span>
          <span className="content">
            {displayValue(contrato.TOMADOR)}
          </span>
        </div>
      </div>
      <Divider borderColor={true} />

      {/* Bloco: Cadência */}
      <div className="info-block">
        <div className="f f-column g1">
          <span className="title-bold">{lang.cadencia}</span>
          <span className="content">{contrato.VOL_CADENCIA + '/' + formatNumber(contrato.QT_CAD)}</span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.ontem}</span>
          <span className="content">{contrato.QT_ONTEM}</span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.hoje}</span>
          <span className="content">{contrato.QT_HOJE}</span>
        </div>
        <div className="f f-column g1">
          <span className="title-bold">{lang.amanha}</span>
          <span className="content">{contrato.QT_AMANHA}</span>
        </div>
      </div>
    </div>

    {!!DS_OBS.length && (
      <>
        <Divider />
        <div className="observations">
          {DS_OBS.map((obs, i) => obs.ds_obs !== "" && <div key={"obs" + i} className={['item', obs.id_color].join(' ')}>{obs.ds_obs}</div>)}
        </div>
      </>
    )}
  </div>
  )
}
