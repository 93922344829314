import ReactDOM from "react-dom"

import 'react-toastify/dist/ReactToastify.css'

import Application from "./App"
import env from "./.env.json"

// 0 = Produção
// 1 = Pré-deploy
// 2 = Homologação
// 3 = Homologação - QA
// 4 = Teste
// 5 = Dev local

ReactDOM.render(<Application env={Number(env ?? '0')} />,
  document.getElementById("root"),
);
