import { useState, useEffect, useContext, useCallback } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import { toast } from 'react-toastify'

export default function GerencialCarregamentoFerro(props) {
  const App = useContext(AppContext),
    lang = { ...App.lang.gerencial_ferro, ...App.lang.global },
    icons = App.icons

  //const today = App.moment().format('yyyy-MM-DD')

  const [lstDados, setLstDados] = useState([]),
    [dtIni, setDtIni] = useState(App.moment().toDate()),
    [dtFim, setDtFim] = useState(App.moment().toDate()),
    [filtro, setFiltro] = useState({ ini: dtIni ?? App.moment().toDate(), fim: dtFim ?? App.moment().toDate() }),
    [lstFiltro, setLstFiltro] = useState({}),
    [loading, setLoading] = useState(null)
  const [activeIndexOperacao, setActiveIndexOperacao] = useState(0);
  const [activeIndexIntervalo, setActiveIndexIntervalo] = useState(0);

  async function get() {
    setLoading(true)

    Promise.all([
      App.api('carregamentoFerro::getDados', {
        periodo: {
          ini: App.moment(dtIni).format('YYYY-MM-DD'),
          fim: App.moment(dtFim).format('YYYY-MM-DD')
        },
        listaBT: filtro?.bts,
      }),
    ]).then(response => {
      setLstDados(response[0].results)

      setLoading(false)
    })
  }

  const getListas = useCallback(() => {
    if (dtIni && dtFim) {
      App.api('carregamentoFerro::getFiltros', {
        periodo: {
          ini: App.moment(dtIni).format('YYYY-MM-DD'),
          fim: App.moment(dtFim).format('YYYY-MM-DD')
        },
      }).then(r => {
        setLstFiltro(r.results);
      });
    }
  }, [dtIni, dtFim, App]);

  function timeToSeconds(time) {
    if (!time) return 0;

    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
  }

  const determineTypeOperation = (value) => {
    const timeInSeconds = timeToSeconds(value);

    if (timeInSeconds <= timeToSeconds("00:02:45")) {
      return "success-b";
    } else if (timeInSeconds <= timeToSeconds("00:03:45")) {
      return "warning-b";
    } else {
      return "danger-b";
    }
  };

  const determineTypeInterval = (value) => {
    const timeInSeconds = timeToSeconds(value);

    if (timeInSeconds <= timeToSeconds("00:01:45")) {
      return "success-b";
    } else if (timeInSeconds <= timeToSeconds("00:02:15")) {
      return "warning-b";
    } else {
      return "danger-b";
    }
  };

  const rangesOperacao = lstDados?.rangesOperacao?.map(item => ({
    name: item.LABEL,
    value: parseInt(item.QUANTITY, 10)
  }));

  const rangesIntervalo = lstDados?.rangesIntervalo?.map(item => ({
    name: item.LABEL,
    value: parseInt(item.QUANTITY, 10)
  }));

  const tempoMedioOperador = lstDados?.tempoMedioOperador?.map(entry => ({
    name: entry.ID_OPR,
    avgOperacao: timeToSeconds(entry.AVG_OPERACAO),
    avgIntervalo: timeToSeconds(entry.AVG_INTERVALO),
    total: entry.TOTAL
  }));

  const sinteticoOperacoes = lstDados?.sintetico?.operacoes?.map(entry => ({
    name: entry.MONTH,
    total: entry.TOTAL,
  }))

  const sinteticoBTs = lstDados?.sintetico?.bts?.map(entry => ({
    name: entry.MONTH,
    total: entry.TOTAL,
  }))

  const sinteticoTempoMedio = lstDados?.sintetico?.tempoMedio?.map(entry => ({
    name: entry.MONTH,
    avgOperacao: timeToSeconds(entry.AVG_OPERACAO),
    avgIntervalo: timeToSeconds(entry.AVG_INTERVALO),
  }));

  const sinteticoRangeOperacao = lstDados?.sintetico?.rangeOperacao?.map(entry => {
    const total = parseInt(entry.TOTAL, 10);

    return {
      name: entry.MONTH,
      "< 02:00": (parseInt(entry["< 02:00"], 10) / total * 100).toFixed(2),
      "02:00 - 03:00": (parseInt(entry["02:00 à 03:00"], 10) / total * 100).toFixed(2),
      "03:01 - 04:00": (parseInt(entry["03:01 à 04:00"], 10) / total * 100).toFixed(2),
      "04:01 - 05:00": (parseInt(entry["04:01 à 05:00"], 10) / total * 100).toFixed(2),
      "05:01 - 06:00": (parseInt(entry["05:01 à 06:00"], 10) / total * 100).toFixed(2),
      "06:01 - 07:00": (parseInt(entry["06:01 à 07:00"], 10) / total * 100).toFixed(2),
      "07:01 - 08:00": (parseInt(entry["07:01 à 08:00"], 10) / total * 100).toFixed(2),
      ">= 00:08:01": (parseInt(entry[">= 00:08:01"], 10) / total * 100).toFixed(2)
    };
  });

  useEffect(() => {
    getListas();
  }, [dtIni, dtFim]);

  useEffect(() => {
    get();

    const reloadInterval = setInterval(() => {
      get();
    }, 1000 * 60 * 10);

    return () => clearInterval(reloadInterval);
  }, []);

  const style = { minWidth: 400 }

  const colors = [
    "#0088FE", // Light Blue
    "#00C49F", // Teal
    "#FFBB28", // Light Orange
    "#FF8042", // Orange
    "#A28BD4", // Lavender
    "#FF6492", // Pink
    "#AFD8F8", // Sky Blue
    "#B0DE09"  // Lime Green
  ];

  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
    if (percent * 100 < 10) {
        return null;
    }

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
        <text
            x={x}
            y={y}
            fill="black"
            textAnchor="middle"
            dominantBaseline="middle"
            fontWeight="bold"
        >
            <tspan x={x} dy="0">{value}</tspan>
            <tspan x={x} dy="20">{`${(percent * 100).toFixed(2)}%`}</tspan>
        </text>
    );
};

const renderCustomLegendPie = (props) => {
  const { payload } = props;

  const total = payload.reduce((acc, entry) => acc + entry.payload.value, 0);

  return (
      <ul style={{ listStyleType: 'none', padding: 0 }}>
          {payload.map((entry, index) => {
              const rawPercentage = (entry.payload.value / total) * 100;
              const percentage = isNaN(rawPercentage) ? "0.00" : rawPercentage.toFixed(2);

              return (
                  <li key={`item-${index}`} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <span style={{ display: 'inline-block', width: '14px', height: '14px', borderRadius: '50%', backgroundColor: entry.color, marginRight: '8px' }}></span>
                      <div className="f g1">
                        <span>{entry.value}</span>-
                        <span style={{color: "#998787"}}>
                          {percentage}%
                        </span>
                      </div>
                  </li>
              );
          })}
      </ul>
  );
};

  const renderCustomLegendBar = (props) => {
    const { payload } = props;
    return (
      <ul style={{ display: 'flex', justifyContent: 'center', padding: 0, listStyleType: 'none' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ fontWeight: 'bold', color: entry.color, marginRight: '10px' }}>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const renderCustomLegendBar2 = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: '0 10px' }}>
            <span style={{ display: 'inline-block', width: '14px', height: '14px', borderRadius: '50%', backgroundColor: entry.color, marginRight: '8px' }}></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, activeIndex, index } = props;
    const isActive = activeIndex === index;
    const activeFill = isActive ? fill : fill;

    const backgroundWidth = 150;
    const backgroundHeight = 80;
    const backgroundX = cx + 200;
    const backgroundY = cy + 100;

    const total = rangesOperacao.reduce((acc, entry) => acc + entry.value, 0);
    const percentage = ((payload.value / total) * 100).toFixed(2);

    return (
        <>
            <c.chart.Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 5}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={activeFill}
            />
            <g style={{ pointerEvents: 'none' }}>
                <rect
                    x={backgroundX}
                    y={backgroundY}
                    width={backgroundWidth}
                    height={backgroundHeight}
                    fill="white"
                    opacity={0.9}
                    rx={5}
                />
                <text x={backgroundX + 10} y={backgroundY + 15} textAnchor="start" dominantBaseline="central" fontWeight="bold">
                    {payload.name}
                </text>
                <text x={backgroundX + 10} y={backgroundY + 45} fill="#998787" textAnchor="start">
                    {`Quantidade: ${payload.value}`}
                </text>
                <text x={backgroundX + 10} y={backgroundY + 65} fill="#998787" textAnchor="start">
                  {`Porcentagem: ${percentage}%`}
                </text>
            </g>
        </>
    );
};

const onPieEnterOperacao = (data, index) => {
    setActiveIndexOperacao(index);
};

const onPieLeaveOperacao = () => {
  setActiveIndexOperacao(null);
};

const onPieEnterIntervalo = (data, index) => {
    setActiveIndexIntervalo(index);
};

const onPieLeaveIntervalo = () => {
  setActiveIndexIntervalo(null);
};

  const handleDateChange = (start, end) => {
    let startDate = App.moment(start).toDate();
    let endDate = end && App.moment(end).toDate();

    if (end && App.moment(endDate).diff(App.moment(startDate), 'days') > 30) {
      toast.warning("Período de consulta limitado em 30 dias");
      endDate = App.moment(startDate).add(30, 'days').toDate();
    }

    setDtIni(startDate);
    setDtFim(endDate);

    end && setFiltro({ ...filtro, ini: startDate, fim: endDate });
  };

  const columnsTable = [
    [lang.bt, 'ID_BT'],
    [lang.sequencia, 'NR_SEQ'],
    [lang.portaria, "PORTARIA"],
    [lang.veiculo, "PLACA"],
    [lang.data_inicio, "DATA_INICIO"],
    [lang.data_fim, "DATA_FIM"],
    [lang.operador, "ID_OPR"],
    [lang.tempo_operacao, "TEMPO_MEDIO"],
    [lang.tempo_intervalo, "TEMPO_TOTAL_INTERVALO"]
  ];

  const percentageOfssBateladas = f.calculatePercentage(
    lstDados?.indicadores?.OFFS,
    lstDados?.indicadores?.TOTAL_BATELADAS
  );

  const volumeTotal = lstDados?.indicadores?.VOLUME_TOTAL || 0;
  const convertedVolumeTotal = f.convertUnit(volumeTotal, "KG", "TON");

  const appendPercentageSymbol = (value) => {
      if (value === null || value === undefined) return "0%";
      return `${value}%`;
  };

  const convertedProporcaoVolume = appendPercentageSymbol(
    f.formatNumber(lstDados?.indicadores_automatico_manual?.QT_PERC_PSG_AUTOMATICO, 2)
  );

  return (<>
    <c.Frame className="filter-frame" autoScroll={false} flex style={{ zIndex: 1 }}>
      {/* <c.Input type="date" label="Data inical" value={filtro.ini} onChange={(e)=>setFiltro({...filtro, ini: e.target.value})} className="f1" />
      <c.Input type="date" label="Data final"  value={filtro.fim} onChange={(e)=>setFiltro({...filtro, fim: e.target.value})} className="f1" /> */}
      <c.DaterangePicker
        label={lang.data}
        startDate={dtIni}
        endDate={dtFim}

        // onChange={(ini, fim) => { setDtIni(ini); setDtFim(fim); setFiltro({ ...filtro, ini: ini, fim: fim }) }}
        onChange={handleDateChange}
      />

      <c.Select value={filtro?.bts} onChange={v => setFiltro({ ...filtro, bts: v.map(v => v.value) })} options={lstFiltro?.bts} label={lang.BT} multi style={style} searchable className="f4" />

      <button onClick={() => get()} disabled={!!App.loading} className="f1">
        {icons.MdSearch({ size: 24 })} {App.lang.global.pesquisar}
      </button>
    </c.Frame>

    {loading ? <App.LoadingComponent /> :
      <>
        <c.Frame title={lang.indicadores_chave} flex>
          <c.Row style={{ width: "100%", gap: "10px" }}>
            <c.Card
              icon={icons.BsClock}
              title={lang.tempo_medio_operacao}
              value={lstDados?.tempos?.AVG_OPERACAO ?? "00:00:00"}
              type={determineTypeOperation(lstDados?.tempos?.AVG_OPERACAO)}
            />
            <c.Card
              icon={icons.BsClock}
              title={lang.tempo_medio_intervalo}
              value={lstDados?.tempos?.AVG_INTERVALO ?? "00:00:00"}
              type={determineTypeInterval(lstDados?.tempos?.AVG_INTERVALO)}
            />
            <c.Card
              icon={icons.FaTruckLoading}
              title={lang.operacoes}
              value={lstDados?.indicadores?.OPERACOES ?? "0"}
              type="info-b"
            />
            <c.Card
              icon={icons.MdTrain}
              title={lang.bts}
              value={lstDados?.indicadores?.BTS ?? "0"}
              type="info-b"
            />
          </c.Row>
          <c.Row style={{ width: "100%", gap: "10px" }}>
            <c.Card
              icon={icons.BsMinecartLoaded}
              title={lang.volume_medio_t}
              value={lstDados?.indicadores?.VOLUME ?? "0"}
              type="info-b"
            />
            <c.Card
              icon={icons.BsMinecartLoaded}
              title={lang.volume_total_t}
              value={convertedVolumeTotal}
              type="info-b"
            />
            <c.Card
              icon={icons.MdOutlineCancelPresentation}
              title={lang.cancelamentos}
              value={lstDados?.indicadores?.CANCELADOS ?? "0"}
              type="success-b"
            />
            <c.Card
              icon={icons.FaPlus}
              title={lang.extras}
              value={lstDados?.indicadores?.EXTRAS ?? "0"}
              type="success-b"
            />
            <c.Card
              icon={icons.MdFlashOff}
              title={lang.offs}
              value={lstDados?.indicadores?.OFFS ?? "0"}
              type="success-b"
              percent={percentageOfssBateladas}
            />
          </c.Row>
          <c.Row style={{ width: "100%", gap: "10px" }}>
            <c.Card
                icon={icons.FaTruckLoading}
                title={lang.bateladas}
                value={lstDados?.indicadores?.TOTAL_BATELADAS ?? "0"}
                type="success-b"
            />
            <c.Card
                icon={icons.FaTruckLoading}
                title={lang.media_bateladas}
                value={lstDados?.indicadores?.MEDIA_BATELADAS ?? "0"}
                type="success-b"
            />
            <c.Card
                icon={icons.BsClock}
                title={lang.tempo_medio_automatico}
                value={lstDados?.indicadores_automatico_manual?.DT_TEMPO_AUTOMATICO ?? "0"}
                type="success-b"
            />
            <c.Card
                icon={icons.BsClock}
                title={lang.tempo_medio_manual}
                value={lstDados?.indicadores_automatico_manual?.DT_TEMPO_MANUAL ?? "0"}
                type="success-b"
            />
            <c.Card
                icon={icons.BsMinecartLoaded}
                title={lang.proporcao_volume_automatico}
                value={convertedProporcaoVolume}
                type="success-b"
            />
          </c.Row>
        </c.Frame>
        <div className="f g2">
          {rangesOperacao && (
            <c.Frame title={lang.tempo_operacao} flex style={{ width: "50%" }}>
                <c.chart.ResponsiveContainer width="100%" minWidth={500} height={400}>
                    <c.chart.PieChart >
                        <c.chart.Pie
                            data={rangesOperacao}
                            cx="50%"
                            cy="50%"
                            innerRadius="35%"
                            outerRadius="90%"
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                            label={renderCustomLabel}
                            labelLine={false}
                            onMouseLeave={onPieLeaveOperacao}
                            onMouseEnter={onPieEnterOperacao}
                            activeIndex={activeIndexOperacao}
                            activeShape={renderActiveShape}
                        >
                            {rangesOperacao.map((entry, index) => (
                                <c.chart.Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            ))}
                        </c.chart.Pie>
                        <c.chart.Legend
                            verticalAlign="middle"
                            align="right"
                            layout="vertical"
                            content={renderCustomLegendPie}
                        />
                    </c.chart.PieChart>
                </c.chart.ResponsiveContainer>
            </c.Frame>
          )}
          {rangesIntervalo && <c.Frame title={lang.tempo_medio_intervalo} flex style={{ width: "50%" }}>
            <c.chart.ResponsiveContainer width="100%" minWidth={500} height={400}>
              <c.chart.PieChart>
                <c.chart.Pie
                  data={rangesIntervalo}
                  cx="50%"
                  cy="50%"
                  innerRadius="35%"
                  outerRadius="90%"
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                  label={renderCustomLabel}
                  labelLine={false}
                  onMouseLeave={onPieLeaveIntervalo}
                  onMouseEnter={onPieEnterIntervalo}
                  activeIndex={activeIndexIntervalo}
                  activeShape={renderActiveShape}
                >
                  {rangesIntervalo?.map((entry, index) => (
                    <c.chart.Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </c.chart.Pie>
                <c.chart.Legend
                  verticalAlign="middle"
                  align="right"
                  layout="vertical"
                  content={renderCustomLegendPie}
                />
              </c.chart.PieChart>
            </c.chart.ResponsiveContainer>
          </c.Frame>}
        </div>
        {tempoMedioOperador && <c.Frame title={lang.tempo_medio_por_operador}>
          <c.chart.ResponsiveContainer width="100%" minWidth={1000} height={600} >
            <c.chart.ComposedChart
              data={tempoMedioOperador}
              layout="vertical"
              maxBarSize={30}
            >
              <c.chart.CartesianGrid strokeDasharray="3 3" />
              {/* <c.chart.XAxis type="number" tickFormatter={secondsToTime} /> */}
              {/* Primary X-axis for time (bottom axis) */}
              <c.chart.XAxis
                type="number"
                tickFormatter={secondsToTime}
                axisLine={false}
                tickLine={false}
                label={{ value: 'Tempo', position: 'insideBottomRight', offset: -5 }}
                domain={[
                  0,
                  (dataMax) => {
                    // Filter out values greater than 1 hour (3600 seconds)
                    const filteredData = tempoMedioOperador.filter(
                      (d) => d.avgOperacao <= 3600 && d.avgIntervalo <= 3600
                    );

                    // Calculate the maximum value of filtered data
                    const maxFilteredValue = Math.max(
                      ...filteredData.map(d => Math.max(d.avgOperacao, d.avgIntervalo))
                    );

                    // Add a small margin to the maximum value
                    return maxFilteredValue + 10;
                  },
                ]}
              />

              {/* Secondary X-axis for quantity (top axis) */}
              <c.chart.XAxis
                type="number"
                orientation="top"
                xAxisId="top"
                axisLine={false}
                tickLine={false}
                domain={[0, (dataMax) => dataMax + 10]}
                label={{ value: 'Quantidade', position: 'insideTopRight', offset: -5 }}
              // allowDataOverflow
              />
              <c.chart.YAxis type="category" dataKey="name" />
              {/* <c.chart.Tooltip formatter={secondsToTime} /> */}
              <c.chart.Tooltip
                formatter={(value, name) => {
                  if (name === 'Tempo Operacional' || name === 'Tempo Intervalo') {
                    return secondsToTime(value);
                  }
                  return value;
                }}
              />
              <c.chart.Legend
                content={renderCustomLegendBar}
              />
              <c.chart.Bar dataKey="avgOperacao" stackId="a" fill="#0088FE" name="Tempo Operacional">
                <c.chart.LabelList dataKey="avgOperacao" content={({ value, x, y, width, height }) => (
                  <text
                    x={x + width - 60}
                    y={y + height / 1.4}
                    dy={-6}
                    // fill="black"
                    fontSize={14}
                    fontWeight="bold"
                    textAnchor="start"
                  >
                    {secondsToTime(value)}
                  </text>
                )} />
              </c.chart.Bar>
              <c.chart.Bar dataKey="avgIntervalo" stackId="a" fill="#FF8042" name="Tempo Intervalo">
                <c.chart.LabelList dataKey="avgIntervalo" content={({ value, x, y, width, height }) => (
                  <text
                    x={x + width - 60}
                    y={y + height / 1.4}
                    dy={-6}
                    // fill="black"
                    fontSize={14}
                    fontWeight="bold"
                    textAnchor="start"
                  >
                    {secondsToTime(value)}
                  </text>
                )} />
              </c.chart.Bar>
              <c.chart.Scatter
                type="linear"
                // unit="Number"
                dataKey="total"
                // stackId="a"
                fill="#B0DE09"
                stroke="#B0DE09"
                name="Quantidade"
              // yAxisId="right"
              // xAxisId={"top"}
              />
            </c.chart.ComposedChart>
          </c.chart.ResponsiveContainer>
        </c.Frame>}
        <c.Frame title={lang.sintetico_mensal} >
          <div className="f g2">
            <div style={{ width: "50%" }}>
              <c.Frame title={lang.operacoes_por_mes}>
                {sinteticoOperacoes &&
                <c.chart.ResponsiveContainer width="100%" minWidth={500} height={400}>
                  <c.chart.BarChart
                    data={sinteticoOperacoes}
                    layout="horizontal"
                  >
                    <c.chart.CartesianGrid strokeDasharray="3 3" />
                    <c.chart.XAxis dataKey="name" />
                    {/* <c.chart.YAxis /> */}
                    <c.chart.YAxis domain={[0, (dataMax) => Math.max(...sinteticoOperacoes.map(d => d.total)) + 10]} />
                    <c.chart.Tooltip />
                    <c.chart.Bar dataKey="total" fill="#0088FE" name="Quantidade">
                      <c.chart.LabelList dataKey="total" content={({ value, x, y, width, height }) => (
                        <text
                          x={x + width - (width / 1.5)}
                          y={y + 20}
                          dy={-6}
                          // fill="black"
                          fontSize={14}
                          fontWeight="bold"
                          textAnchor="start"
                        >
                          {value}
                        </text>
                      )} />
                    </c.chart.Bar>
                  </c.chart.BarChart>
                </c.chart.ResponsiveContainer>
                }
              </c.Frame>
            </div>
            <div style={{ width: "50%" }}>
              <c.Frame title={lang.bts_por_mes}>
                {sinteticoBTs &&
                <c.chart.ResponsiveContainer width="100%" minWidth={500} height={400}>
                  <c.chart.BarChart
                    data={sinteticoBTs}
                    layout="horizontal"
                  >
                    <c.chart.CartesianGrid strokeDasharray="3 3" />
                    <c.chart.XAxis dataKey="name" />
                    {/* <c.chart.YAxis /> */}
                    <c.chart.YAxis domain={[0, (dataMax) => Math.max(...sinteticoBTs.map(d => d.total))]} />
                    <c.chart.Tooltip />
                    <c.chart.Bar dataKey="total" fill="#00C49F" name="Quantidade">
                      <c.chart.LabelList dataKey="total" content={({ value, x, y, width, height }) => (
                        <text
                          x={x + width - (width / 1.5) + (value.length * 5)}
                          y={y + 20}
                          dy={-6}
                          // fill="black"
                          fontSize={14}
                          fontWeight="bold"
                          textAnchor="start"
                        >
                          {value}
                        </text>
                      )} />
                    </c.chart.Bar>
                  </c.chart.BarChart>
                </c.chart.ResponsiveContainer>
                }
              </c.Frame>
            </div>

          </div>
          <div style={{ width: "100%", height: "100%" }}>
            <c.Frame title={lang.tempo_medio_operacao_x_intervalo_por_mes}>
              <c.chart.ResponsiveContainer width="100%" minWidth={500} height={250}>
                <c.chart.BarChart
                  data={sinteticoTempoMedio}
                  layout="vertical"
                  maxBarSize={30}
                >
                  <c.chart.CartesianGrid strokeDasharray="3 3" />
                  <c.chart.XAxis type="number" tickFormatter={secondsToTime} />
                  <c.chart.YAxis type="category" dataKey="name" />
                  <c.chart.Tooltip formatter={secondsToTime} />
                  <c.chart.Legend
                      content={renderCustomLegendBar}
                  />
                  <c.chart.Bar dataKey="avgOperacao" stackId="a" fill="#A28BD4" name="Tempo Operacional">
                    <c.chart.LabelList dataKey="avgOperacao" content={({ value, x, y, width, height }) => (
                      <text
                        /*                           x={x + width / 4}
                                                  y={y + 20} */
                        x={x + width - 60}
                        y={y + height / 1.15}
                        dy={-6}
                        fill="white"
                        fontSize={14}
                        fontWeight="bold"
                        textAnchor="start"
                      >
                        {secondsToTime(value)}
                      </text>
                    )} />
                  </c.chart.Bar>
                  <c.chart.Bar dataKey="avgIntervalo" stackId="a" fill="#FF6492" name="Tempo Intervalo">
                    <c.chart.LabelList dataKey="avgIntervalo" content={({ value, x, y, width, height }) => (
                      <text
                        /*                           x={x + width - 70}
                                                  y={y + 20} */
                        x={x + width - 60}
                        y={y + height / 1.15}
                        dy={-6}
                        fill="white"
                        fontSize={14}
                        fontWeight="bold"
                        textAnchor="start"
                      >
                        {secondsToTime(value)}
                      </text>
                    )} />
                  </c.chart.Bar>
                </c.chart.BarChart>
              </c.chart.ResponsiveContainer>
            </c.Frame>
          </div>
        </c.Frame>
        <c.Frame title={lang.tempo_de_operacao_por_mes}>
          <c.chart.ResponsiveContainer width="100%" minWidth={1000} height={400}>
            {sinteticoRangeOperacao &&
              <c.chart.BarChart data={sinteticoRangeOperacao} layout="vertical">
                <c.chart.CartesianGrid strokeDasharray="3 3" />
                <c.chart.XAxis type="number" domain={[0, 100]} tickCount={11} allowDataOverflow />
                <c.chart.YAxis type="category" dataKey="name" />
                <c.chart.Tooltip />
                <c.chart.Legend content={renderCustomLegendBar2} />
                {Object.keys(sinteticoRangeOperacao[0]).filter(key => key !== "name").map((key, index) => (
                  <c.chart.Bar key={key} dataKey={key} stackId="a" fill={colors[index]} name={key}>
                    <c.chart.LabelList dataKey={key} content={({ value, x, y, width, height }) => (
                      value > 5 &&
                      <text
                        x={x + (width / 2.5)}
                        y={y + height / 1.4}
                        dy={-6}
                        // fill="white"
                        fontSize={14}
                        fontWeight="bold"
                        textAnchor="start"
                      >
                        {value} %
                      </text>
                    )} />
                    {/* <c.chart.LabelList /> */}
                  </c.chart.Bar>
                ))}
              </c.chart.BarChart>
            }
          </c.chart.ResponsiveContainer>
        </c.Frame>
        <c.Frame title={lang.analitico_operacional}>
          {lstDados?.analitico &&
            <c.Table
              data={lstDados?.analitico}
              columns={columnsTable}
              showFilters={true}
            // filter={}
            />
          }
        </c.Frame>
      </>}
  </>)
}

