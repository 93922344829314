import { useState, useEffect, useContext } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'

export default function OrdemCarregamentoDetailsModal({ ordem = null, prtId = null, onClose }) {
  const App = useContext(AppContext),
    lang = { ...App.lang.global, ...App.lang.ordem_carregamento },
    icons = App.icons

  const [oc, setOc] = useState({}),
    [loading, setLoading] = useState(null)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)

      if (!!ordem) {
        setOc(ordem)
      } else {
        let id = (await App.api('ordemCarregamento::getByPortaria', { prtId })).results

        if (!id) {
          App.toast.error('Falha ao buscar OC')
          onClose()
        } else {
          setOc(
            (await App.api('ordemCarregamento::getById', { id })).results
          )
        }
      }

      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <c.Modal title={!!oc?.ID_UOCC ? 'Detalhes da Ordem de carregamento nº ' + oc?.ID_UOCC : ''}
      onClose={onClose} cancelText={lang.fechar} loading={loading}
    >
      {!!oc &&
        <div className="f g2 f-column">
          <div className="f g2 w100">
            <c.Span label="ID" value={oc?.ID_UOCC} />
            <c.Span label={lang.data} value={oc?.DT_CDT} />
            <c.Span label={lang.tipo} value={oc?.TP_FRT} />
            <c.Span label={lang.locais_carregamento} value={oc?.LOCAIS_CARREGAMENTO} />
            <c.Span label={lang.peso_carga} value={oc?.PESO_CARGA} />
            <c.Span label={lang.capacidade_caminhao} value={oc?.CAPACIDADE_VCL} />
          </div>

          <div className="f g2 w100">
            <div className="f g2 f-column w50">
              {/* Itens da OC */}
              <c.Frame title={lang.itens_oc} className="w100">
                <c.Table data={oc?.ITENS} noFooter
                  columns={[
                    [lang.pedido, 'ID_CN'],
                    [lang.cliente, 'NM_CLIENTE'],
                    [lang.produto, 'NM_PRO'],
                    [lang.quantidade, '_QUANTIDADE'],
                  ]} />
              </c.Frame>

              {/* Resumo transportadoras */}
              <c.Frame title={lang.transportadoras} className="w100">
                {oc?.TRANSPORTADORAS?.map(t => t.NAME).join(', ')}
                {<br />}
                {<b> {lang.frete}: </b>}
                {oc?.VL_TRF}
              </c.Frame>

              {/* Resumo Observações */}
              <c.Frame title={lang.obs} className="w100">
                {!oc?.OBSS?.length
                  ? lang.nothing_here
                  : <div className="f g1 f-column">
                    {oc?.OBSS?.map((obs, i) =>
                      <div key={i} style={{
                        borderLeftStyle: 'solid', borderWidth: 5,
                        borderColor: 'var(--' + obs.id_color + ')',
                      }} className="p1">{obs.ds_obs}</div>
                    )}
                  </div>
                }
              </c.Frame>
            </div>

            <div className="f g2 f-column w50">
              {/* Locais e Turnos */}
              <c.Frame title={lang.locais_turnos} className="w100">
                <c.Table noFooter
                  data={
                    Object.values(
                      oc?.ORIGENS?.reduce((acc, l) => ({
                        ...acc,
                        [l.NR_CNPJUND]: {
                          ...acc[l.NR_CNPJUND],
                          NM_PES: l.NM_PES,
                          DS_TRN: l.DS_TRN,
                          DT_CRG: l.DT_CRG,
                          ITENS: l.ITENS.reduce((itensAcc, item) => {
                            const nomeProduto = oc?.ITENS.find(oi => oi.ID_PRO === item.ID_PRO)?.NM_PRO;
                            if (nomeProduto && !itensAcc.some(i => i.NM_PRO === nomeProduto)) {
                              return [...itensAcc, { ...item, NM_PRO: nomeProduto }];
                            }
                            return itensAcc;
                          }, []),
                        },
                      }), {}) ?? {}
                    ).map(l => ({
                      ...l,
                      PRODUTOS_CONCAT: l.ITENS.map(p => {
                        return p.QT_EXP + ' ' + p.ID_UNDMEXP + ' x ' + p.NM_PRO
                      }).reduce((acc, p) => !!acc ? <>{acc}<br />{p}</> : p),
                      TURNO_CONCAT: App.moment(l.DT_CRG).format('DD/MM/YYYY') + ' '
                        + l.DS_TRN
                    }))
                  } columns={[
                    [lang.local, 'NM_PES'],
                    [lang.produtos, 'PRODUTOS_CONCAT'],
                    [lang.data_e_turno, 'TURNO_CONCAT'],
                  ]} />
              </c.Frame>

              {/* Destinos */}
              <c.Frame title={lang.destinos} className="w100">
                <c.Table noFooter
                  data={
                    Object.values(
                      oc?.DESTINOS?.reduce((acc, l) => ({
                        ...acc,
                        [l.NR_CPFCNPJ]: {
                          ...acc[l.NR_CPFCNPJ],
                          NM_PES: l.NM_PES,
                          ITENS: l.ITENS.map(item => ({
                            ...item,
                            NM_PRO: oc?.ITENS.find(oi => oi.ID_PRO === item.ID_PRO)?.NM_PRO
                          })),
                        }
                      }), {}) ?? {}
                    ).map(l => ({
                      ...l,
                      PRODUTOS_CONCAT: l.ITENS.map(p => {
                        return p.QT_EXP + ' ' + p.ID_UNDMEXP + ' x ' + p.NM_PRO
                      }).reduce((acc, p) => !!acc ? <>{acc}<br />{p}</> : p),
                    }))
                  } columns={[
                    [lang.local, 'NM_PES'],
                    [lang.produtos, 'PRODUTOS_CONCAT'],
                  ]} />
              </c.Frame>
            </div>
          </div>
        </div>
      }
    </c.Modal >
  )
}
