import { useCallback, useContext, useMemo, useState } from "react"

import { AppContext } from "../../../../App";
import { Checkbox, Frame, Table } from "../../../../c"
import { formatCnpj, formatDate, formatMoney, formatNumber, t } from "../../../../f";

import { handleDetatilsActions } from "../QuotationTable/useCases/handleDetails";

export const ManagerQuotationDetail = ({ record, setEditing, onRelaod }) => {
  const App = useContext(AppContext)

  const situation = record?.situation?.code
  const [loading, setLoading] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState([])

  const handleSelect = useCallback((detail) => {
    return (selected) => {
      setSelectedDetails((prevState) => {
        const selectedDetailIds = prevState.map(({ shippingCompany: { id } = {}}) => id);

        if (selected) {
          if (!selectedDetailIds.includes(detail.shippingCompany?.id ?? '')) {
            return [...prevState, detail];
          }
        } else {
          return prevState.filter(({ shippingCompany: { id } = {} }) => id !== detail.shippingCompany?.id);
        }

        return prevState;
      });
    };
  }, []);

  const detailsLength = useMemo(() => record.details.length, [record.details.length])
  const selectedDetailsLength = useMemo(() => selectedDetails.length, [selectedDetails.length])

  const lang = useMemo(() => ({
    ...App.lang.global,
    ...App.lang.bid
  }), [App.lang.bid, App.lang.global])

  const details = useMemo(() => {
    const { details: rawDetails, ...data } = record
    const selectedDetailIds = selectedDetails.map(({ shippingCompany: { id } = {}}) => id)

    const result = rawDetails
      .sort((a, b) => {
        const aAmount = a.amount != null ? a.amount : Infinity;
        const bAmount = b.amount != null ? b.amount : Infinity;
        const { shippingCompany: { name: aName = '', cnpj: aCnpj = '' } = {} } = a;
        const { shippingCompany: { name: bName = '', cnpj: bCnpj = '' } = {} } = b;

        if (aAmount < bAmount) return -1;
        if (aAmount > bAmount) return 1;

        const aTerm = `${aName} - ${aCnpj}`.toLowerCase();
        const bTerm = `${bName} - ${bCnpj}`.toLowerCase();

        if (aTerm < bTerm) return -1;
        if (aTerm > bTerm) return 1;

        return 0;
      })
      .map((detail) => {
        const checked = selectedDetailIds.includes(detail?.shippingCompany?.id ?? '') || !!detail?.approved;
        const key = `${data.id}-${detail.shippingCompany?.id}-${checked}`

        return {
          selectable: (
            <Checkbox
              checked={checked}
              className="column-field"
              onToggle={handleSelect(detail)}
              disabled={situation !== 'approval'}
            />
          ),
          CNPJ: formatCnpj(detail.shippingCompany?.cnpj ?? ''),
          NM_PESRED: detail.shippingCompany?.name ?? '',
          VL_LANCE: detail.amount ? formatMoney(Number(detail.amount), 2) : '',
          DT_LANCE: detail.createdAt ? formatDate(detail.createdAt) : '',
          QT_VCL: detail.vehicleQuantity ? formatNumber(detail.vehicleQuantity) : '',
          actions: handleDetatilsActions(record, detail, setEditing),
          record: { ...data, detail },
          key,
        }
    });

    return result;
  }, [handleSelect, record, selectedDetails, setEditing, situation]);

  const columns = useMemo(() => {
    const allSelected = detailsLength === selectedDetailsLength && selectedDetailsLength > 0
    const key = `${record.id}-${allSelected}-all${detailsLength}-selected${selectedDetailsLength}`

    const handleSelectAll = (selected) => {
      setSelectedDetails(selected ? details.map(({ record: { detail } }) => detail) : [])
    }

    let checkboxColumn = []
    if(
      (App.user.in_uorgadm === 'T' && ['approval', 'finished'].includes(situation)) ||
      (App.user.in_trp === 'T' && situation === 'finished')
    ) {
      let checkboxField = (
        <Checkbox
          key={key}
          checked={allSelected}
          className="column-field"
          onToggle={handleSelectAll}
          disabled={situation === 'finished' }
        />
      )

      if(situation !== 'approval') checkboxField = ''

      checkboxColumn = [[checkboxField, "selectable", {}, "f", undefined, key]]
    }

    return [
      ...checkboxColumn,
      ["CNPJ", "CNPJ", {}, "f"],
      ["Transportadora", "NM_PESRED", {}, "f"],
      ["Data do lance", "DT_LANCE", {}, "f"],
      ["Valor", "VL_LANCE", {}, "f"],
      ["Número de veículos", "QT_VCL", {}, "center f"],
      ["Ações", 'actions', {}, 'center f g1'],
    ]
  }, [App.user.in_trp, App.user.in_uorgadm, details, detailsLength, record.id, selectedDetailsLength, situation]);

  const frameActions = useMemo(() => {
    const {
      id,
      endQuotation,
      itinerary: { id: itineraryId },
      situation: { code: situationCode } = {},
    } = record
    const actions = {}

    if(situationCode === 'approval') {
      actions.approve = {
        enabled: !loading && selectedDetails.length > 0 && selectedDetails.every(({ amount }) => amount != null),
        label: t(lang, 'approve'),
        onClick: async () => {
          try {
            setLoading(true)

            await App.api('CreateQuotationTariff', {
              id,
              itineraryId,
              endQuotation,
              bids: selectedDetails.map(({ shippingCompany, amount }) => ({
                amount,
                shippingCompanyId: shippingCompany.id,
              })),
            })

            if(onRelaod) onRelaod()
          } finally {
            setLoading(false)
          }
        },
      }
    }

    return actions
  }, [App, lang, loading, onRelaod, record, selectedDetails])

  return (
    <div>
      <Frame
        title='Lances das transportadoras'
        actions={frameActions}
        loading={loading}
      >
        <Table
          isSub
          columns={columns}
          data={details}
          disableLine={'disable'}
          fixedPerPage={5}
        />
      </Frame>
    </div>
  )
}
