import { useCallback, useContext, useEffect, useState } from "react"
import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
import Leaflet from "leaflet"

import { AppContext } from "../../../../App"
import { Checkbox, Divider, Input, Modal, Select } from "../../../../c"
import { formatCurrency, formatNumber, toFloat } from "../../../../f"

export function ContratoIncluirModal({ onFinish, onClose }) {
  const App = useContext(AppContext),
    lang = { ...App.lang.global, ...App.lang.contratos },
    icons = App.icons

  const [step, setStep] = useState(0),
    [listas, setListas] = useState({}),
    [listasOrigens, setListasOrigens] = useState([]),
    [listasDestinos, setListasDestinos] = useState([]),
    [loading, setLoading] = useState(false),
    [loadingMap, setLoadingMap] = useState(false),
    [pedagios, setPedagios] = useState([]),
    [rotaOrigemDestino, setRotaOrigemDestino] = useState([]),
    [posOrigemDestino, setPosOrigemDestino] = useState({ inicio: [], fim: [] }),
    [rota, setRota] = useState({ lat_lng_orig: [], lat_lng_dest: [] }),
    [position, setPosition] = useState([-23.415881526446956, -51.93903347350793]),
    [keyMAP, setKeyMAP] = useState(Math.random()),
    [listaDestino, setListaDestino] = useState([]),
    [listaOrigem, setListaOrigem] = useState([]),
    [listasDestinatario, setListasDestinatario] = useState([]),
    [data, setData] = useState({
      tp_oprprt: "R", tp_frt: "C",
      qt_conversao: 37, lst_transp: [],
      obs: [],
      id_ext: ''
    }),
    [edDest, setEdDest] = useState({
      NM_LGD: '',
      NR_LGD: '',
      NM_BRO: '',
      NM_LOC: '',
      CD_IBGE_LOC: '',
      ID_UF: '',
      NM_PAIS: '',
      ID_OBJUNC: ''
    }),
    [edRmt, setEdRmt] = useState({
      NM_LGD: '',
      NR_LGD: '',
      NM_BRO: '',
      NM_LOC: '',
      CD_IBGE_LOC: '',
      ID_UF: '',
      NM_PAIS: '',
      ID_OBJUNC: ''
    })

  const steps = [
    {
      label: lang.contrato,
      validate: !!data.nr_cnpjund && !!data.tp_oprprt && !!data.tp_frt && !!data.nr_cnpjprc && !!data.nr_cnpjrmt && !!data.nr_cnpjdst
    },
    {
      label: 'Endereço',
      validate: true
    },
    { label: lang.rota, validate: !!data.nr_cnpjexp && !!data.nr_cnpjrcb },
    { label: lang.transportadora, validate: !!data.lst_transp?.length },
    {
      label: lang.lote,
      validate: !!data.id_pro && !!data.dt_inicio && !!data.qt_volume && !!data.qt_cadencia && !!data.qt_conversao && !!data.qt_cota && !!data.vl_frete && data.qt_cadencia >= data.qt_conversao
    },
    { label: lang.obs, validate: true },
    { label: lang.resumo, validate: true },
  ]

  const iconePedagio = new Leaflet.divIcon({
    className: "",
    iconSize: [32, 32], iconAnchor: [4, 10],
    labelAnchor: [-4, 0], popupAnchor: [0, -5],
    html: `<span style="color:#d04933;" class="fa fa-circle"></span>`,
  })

  function atualizarCustos(lat_lng_orig, lat_lng_dest) {
    fetch('https://velog.vertti.com.br/api/roteiro', {
      method: 'POST', headers: { 'content-type': 'application/json, text/plain, */*' },
      body: JSON.stringify({
        sn_lat_lng: true, sn_rota_alternativa: false, sn_pedagio: true, sn_balanca: true,
        tag: "TRUCK", sn_calcu_volta: false, qtd_eixos: 6, veiculo_km_litro: 0, valor_medio_combustivel: 0,
        rotas: [{ lat: lat_lng_orig[1], lng: lat_lng_orig[0] }, { lat: lat_lng_dest[1], lng: lat_lng_dest[0] }],
      })
    }).then(r => r.json()).then(r => {
      setPedagios(r[0].track.rotas[0].pedagios)
      setRotaOrigemDestino(r[0].track.rotas[0].lng_lat)
      setPosOrigemDestino({
        inicio: [
          r[0].track.rotas[0].lng_lat[0][1],
          r[0].track.rotas[0].lng_lat[0][0],
        ],
        fim: [
          r[0].track.rotas[0].lng_lat[r[0].track.rotas[0].lng_lat.length - 1][1],
          r[0].track.rotas[0].lng_lat[r[0].track.rotas[0].lng_lat.length - 1][0],
        ],
      })

      setData({
        ...data,
        vl_pedagio: (r[0].track.rotas[0].vl_total_pedagio_original / 6)?.toFixed(2) ?? 0,
        qt_km: r[0].track.rotas[0].distancia,
        hr_duracao: r[0].track.rotas[0].duracao
      })
    })
  }

  async function atualizaPedagios() {
    if (!!data.nr_cnpjexp && !!data.nr_cnpjrcb) {
      setLoadingMap(true)
      const origem = !!edRmt.ID_OBJUNC ? listaOrigem?.find(o => parseInt(o.value) === parseInt(edRmt.ID_OBJUNC)) : null
      const destino = !!edDest.ID_OBJUNC ? listaDestino?.find(o => parseInt(o.value) === parseInt(edDest.ID_OBJUNC)) : null

      if (origem !== null && destino !== null) {
        const posOrigem = await buscarLongitudeLatitude(origem, 'destino')
        const posDestino = await buscarLongitudeLatitude(destino, 'destino')

        setRota({ ...rota, lat_lng_orig: posOrigem, lat_lng_dest: posDestino })
        setPosition([posOrigem[1], posOrigem[0]])
        atualizarCustos(posOrigem, posDestino)
      }
      setLoadingMap(false)
    }
  }

  async function buscarLongitudeLatitude(objeto, posicao = '') {
    let r = '';
    let endereco = '';
    let enderecoOrigemSemLGD = '';

    if (posicao !== '') {
      endereco = `${objeto?.NM_LGD}, ${objeto?.NR_LGD}, ${objeto?.NM_LOC}, ${objeto?.ID_UF}, ${objeto?.NM_PAIS}`;
      enderecoOrigemSemLGD = `${objeto?.NM_LOC}, ${objeto?.ID_UF}, ${objeto?.NM_PAIS}`;
    }
    else {
      endereco = `${objeto?.adicional?.nm_lgd}, ${objeto?.adicional?.nr_lgd}, ${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
      enderecoOrigemSemLGD = `${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
    }

    r = (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${endereco}&format=geojson`)).json()).features[0]?.geometry?.coordinates
      ?? (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${enderecoOrigemSemLGD}&format=geojson`)).json()).features[0]?.geometry?.coordinates

    return r;
  }

  function submit() {
    setLoading(true)
    return App.api('contratos::gravar', { ...data, obs: data.obs.filter(o => !!o.ds_obs), edDest: edDest, edRmt: edRmt })
      .then(r => {
        setLoading(false)
        if (r.status) onFinish()
        setLoading(false)
        return r.status
      })
  }

  useEffect(() => atualizaPedagios(), [data.nr_cnpjexp, data.nr_cnpjrcb, data.vl_pedagio, edDest, edRmt])
  useEffect(() => rotaOrigemDestino.length > 0 && setKeyMAP(Math.random()), [rotaOrigemDestino])

  // Auto-preencher origem e destino
  useEffect(() => {
    if (!!data.nr_cnpjrmt) {
      App.api('fob::getEmbarcadores', { adicionais: true, s: data.nr_cnpjrmt }).then(r => {
        setListas({ ...listas, origens: r.results, parceiros: r.results, remetentes: r.results })
        let id_rmt = r.results[0]?.adicional?.id_pes

        if(id_rmt){
          App.api('contratos::getEndereco',{id_cli: id_rmt}).then(ed =>{
            const ed_def = ed.results.filter(item => item.IN_DEF == 'T')[0] ?? ed.results[0];
            setListaOrigem(ed.results.map((e,i) =>({

              ...e,
              value: e.ID_OBJUNC,
              label: e.NM_LGD ?? e.NM_LOC
            })));

            setEdRmt(ed_def)
          })
        }
      })
      // console.log("Origem")
      setData({ ...data, nr_cnpjexp: data.nr_cnpjrmt })

    }
  }, [data.nr_cnpjrmt])

  useEffect(() => {
    setData({ ...data, nr_cnpjexp: data.nr_cnpjrmt })
  }, [listaOrigem])

  useEffect(() => {
    if (!!data.nr_cnpjdst) {
      App.api('fob::getEmbarcadores', { adicionais: true, s: data.nr_cnpjdst }).then(r => {
        setListasDestinatario(r.results)
        let id_dest = r.results[0]?.adicional?.id_pes
        if (id_dest) {
          App.api('contratos::getEndereco', { id_cli: id_dest }).then(ed => {
            const ed_def = ed.results.filter(item => item.IN_DEF == 'T')[0];
            setListaDestino(ed.results.map((e, i) => ({
              ...e,
              value: e.ID_OBJUNC,
              label: e.NM_LGD ?? e.NM_LOC
            })));

            setEdDest(ed_def)
          })
        } else {
          setData({ ...data, nr_cnpjrcb: data.nr_cnpjdst })
        }

      })
    }
  }, [data.nr_cnpjdst])

  useEffect(() => {
    setData({ ...data, nr_cnpjrcb: data.nr_cnpjdst })
  }, [listaDestino])

  useEffect(() => {
    setLoading(true)
    Promise.all([
      // App.api('fob::getUnidades'),
      // App.api('fob::getParceiros'),
      // App.api('fob::getEmbarcadores', {adicionais: true}),
      App.api('fob::getProdutos'),
      App.api('fob::getTurnos'),
      App.api('transportadoras::get'),
    ]).then(r => {
      setListas({
        //unidades: r[0].results,
        //parceiros: r[1].results,
        //remetentes: r[2].results,
        //destinatarios: r[2].results,
        //origens: r[2].results,
        //destinos: r[2].results,
        // produtos: r[3].results,
        // turnos: r[4].results,
        // transportadoras: r[5].results.map((v,i)=>({value: v.NR_CNPJTRP, label: v.NM_TRP, key: i}))
        produtos: r[0].results,
        turnos: r[1].results,
        transportadoras: r[2].results.map((v, i) => ({ value: v.NR_CNPJTRP, label: v.NM_TRP, key: i }))
      })
      setLoading(false)
    })
  }, [])

  const handleGetItinerario = useCallback((e) => {
    const id_ext = data.id_ext;
    if (id_ext) {
      App.api('contratos::getItinerario', { id_ext })
        .then(r => {
          let nr_cnpjrmt = r.results.origem_cpfcnpj;
          let nr_cnpjdst = r.results.destino_cpfcnpj;
          setData({
            ...data,
            nr_cnpjrmt: nr_cnpjrmt,
            nr_cnpjdst: nr_cnpjdst,
            nr_cnpjrcb: nr_cnpjdst,
            nr_cnpjprc: nr_cnpjrmt,
            nr_cnpjexp: nr_cnpjrmt
          });
        })
        .catch(error => {
          console.error('Error fetching itinerario:', error);
        });
    }
  }, [data.id_ext]);

  const handleItinerario = useCallback((e) => {
    setData(prevData => ({ ...prevData, id_ext: e.target.value }));
  }, [])

  return (
    <Modal largeWidth title={lang.inclusao_contrato} steps={steps} onStepChange={s => setStep(s)} loading={loading}
      validate={steps.every(s => s.validate)} contentHeight={400} onFinish={submit} onClose={onClose}
    >
      {/* Contrato */}
      {(step === 0 && App.user.in_uorgadm === 'T' && App.components.itinerarios) ? <>
        <div className="f f-wrap g1 w100">
          <Select label={lang.unidade} options={listas.unidades} value={data.nr_cnpjund} onChange={e => setData({ ...data, nr_cnpjund: e.value })} error={!data.nr_cnpjund} searchable className="w50"
            onInputChange={(v, { action }) => {
              if (v.length >= 3 && action === 'input-change') App.api('fob::getUnidades', { s: v }).then(r => setListas({ ...listas, unidades: r.results }))
              return v
            }}
          />
          <Select label={lang.operacao} options={[{ value: 'R', label: lang.recepcao }, { value: 'E', label: lang.expedicao }]} value={data.tp_oprprt} onChange={e => setData({ ...data, tp_oprprt: e.value })} className="f1" />
          <Select label={lang.frete} options={[{ value: 'C', label: lang.cif }, { value: 'F', label: lang.fob }]} value={data.tp_frt} onChange={e => setData({ ...data, tp_frt: e.value })} className="f1" />
        </div>
        <Select label={'Tomador'} options={listas.tomadores} value={data.nr_cnpjtmd} onChange={e => setData({ ...data, nr_cnpjtmd: e.value })} searchable className="w100"
          onInputChange={(v, { action }) => {
            if (v.length >= 3 && action === 'input-change') App.api('fob::getTomadores', { s: v }).then(r => setListas({ ...listas, tomadores: r.results }))
            return v
          }}
        />

        <Select
          /* Lucas, VD-22920, 15/07/2024 */
          label={'Área de Negócio'}
          options={listas.areasNegocio}
          value={data.id_area_negocio}
          onChange={e => setData({ ...data, id_area_negocio: e.value })}
          searchable
          className="w100"
          onInputChange={(v, { action }) => {
            if (v.length >= 3 && action === 'input-change') App.api('fob::getAreasdeNegocio', { s: v }).then(r => setListas({ ...listas, areasNegocio: r.results }))
            return v;
          }}
        />
        <Divider />

        <Input label={'Itinerario'} value={data.id_ext ?? ''} type="text" error={false} className="w100" icon='search'
          onChange={(e) => handleItinerario(e)}
          iconClick={handleGetItinerario}
        />

        <Divider text={'Ou'} />
        <div className="f f-wrap g1 w100">
          <Select label={lang.cliente} options={listas.parceiros} value={data.nr_cnpjprc} onChange={e => setData({ ...data, nr_cnpjprc: e.value })} error={!data.nr_cnpjprc} searchable className="w100"
            onInputChange={(v, { action }) => {
              if (v.length >= 3 && action === 'input-change') App.api('fob::getParceiros', { s: v }).then(r => setListas({ ...listas, parceiros: r.results }))
              return v
            }}
          />
          <Select label={lang.remetente} options={listas.remetentes} value={data.nr_cnpjrmt} onChange={e => setData({ ...data, nr_cnpjrmt: e.value })} error={!data.nr_cnpjrmt} searchable className="w100"
            onInputChange={(v, { action }) => {
              if (v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores', { adicionais: true, s: v }).then(r => setListas({ ...listas, remetentes: r.results }))
              return v
            }}
          />

          <Select label={lang.destinatario} options={listasDestinatario} value={data.nr_cnpjdst} onChange={e => setData({ ...data, nr_cnpjdst: e.value })} error={!data.nr_cnpjdst} searchable className="w100"
            onInputChange={(v, { action }) => {
              if (v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores', { adicionais: true, s: v }).then(r => setListasDestinatario(r.results))
              return v
            }}
          />
        </div>
      </>
        :
        step === 0 && <>
          <div className="f f-wrap g1 w100">

            <Select label={lang.unidade} options={listas.unidades} value={data.nr_cnpjund} onChange={e => setData({ ...data, nr_cnpjund: e.value })} error={!data.nr_cnpjund} searchable className="w50"
              onInputChange={(v, { action }) => {
                if (v.length >= 3 && action === 'input-change') App.api('fob::getUnidades', { s: v }).then(r => setListas({ ...listas, unidades: r.results }))
                return v
              }}
            />
            <Select label={lang.operacao} options={[{ value: 'R', label: lang.recepcao }, { value: 'E', label: lang.expedicao }]} value={data.tp_oprprt} onChange={e => setData({ ...data, tp_oprprt: e.value })} className="f1" />
            <Select label={lang.frete} options={[{ value: 'C', label: lang.cif }, { value: 'F', label: lang.fob }]} value={data.tp_frt} onChange={e => setData({ ...data, tp_frt: e.value })} className="f1" />

            <Select label={lang.cliente} options={listas.parceiros} value={data.nr_cnpjprc} onChange={e => setData({ ...data, nr_cnpjprc: e.value })} error={!data.nr_cnpjprc} searchable className="w100"
              onInputChange={(v, { action }) => {
                if (v.length >= 3 && action === 'input-change') App.api('fob::getParceiros', { s: v }).then(r => setListas({ ...listas, parceiros: r.results }))
                return v
              }}
            />

            <Select label={'Tomador'} options={listas.tomadores} value={data.nr_cnpjtmd} onChange={e => setData({ ...data, nr_cnpjtmd: e.value })} searchable className="w100"
              onInputChange={(v, { action }) => {
                if (v.length >= 3 && action === 'input-change') App.api('fob::getTomadores', { s: v }).then(r => setListas({ ...listas, tomadores: r.results }))
                return v
              }}
            />

            <Select
              /* Lucas, VD-22920, 15/07/2024 */
              label={'Área de Negócio'}
              options={listas.areasNegocio}
              value={data.id_area_negocio}
              onChange={e => setData({ ...data, id_area_negocio: e.value })}
              searchable
              className="w100"
              onInputChange={(v, { action }) => {
                if (v.length >= 3 && action === 'input-change') App.api('fob::getAreasdeNegocio', { s: v }).then(r => setListas({ ...listas, areasNegocio: r.results }))
                return v;
              }}
            />

        <Select label={lang.remetente} options={listas.remetentes} value={data.nr_cnpjrmt} onChange={e=>setData({...data, nr_cnpjrmt: e.value})} error={!data.nr_cnpjrmt} searchable className="w100"
          onInputChange={(v, {action}) => {
            if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, remetentes: r.results}))
            return v
          }}
        />

        <Select label={lang.destinatario} options={listas.destinatarios} value={data.nr_cnpjdst} onChange={e=>setData({...data, nr_cnpjdst: e.value})} error={!data.nr_cnpjdst} searchable className="w100"
          onInputChange={(v, {action}) => {
            if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, destinatarios: r.results}))
            return v
          }}
        />
      </div>
    </>}

    {/* Endereço */}
    {step===1&&
      <>
      <div className="f g2 w100">
        <div className="f g1 f-column f1">
          <Select label={'Endereço Origem'} options={listaOrigem} value={edRmt.ID_OBJUNC} error={false} className="w100"
            onChange={e => setEdRmt(listaOrigem.find(obj => obj.ID_OBJUNC === e?.value)) } defaultValue={edRmt.ID_OBJUNC} searchable />
          {/* <Input label={'Endereço Origem'}       value={edRmt.NM_LGD}   onChange={e => {}} type="text" error={false} className="w100" disabled/> */}
          <Input label={'Destino'}       value={edRmt.NM_LGD}        onChange={e => setEdRmt({...edRmt,NM_LGD:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'Número'}       value={edRmt.NR_LGD}        onChange={e => setEdRmt({...edRmt,NR_LGD:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'Bairro'}       value={edRmt.NM_BRO}        onChange={e => setEdRmt({...edRmt,NM_BRO:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'Cidade'}       value={edRmt.NM_LOC}        onChange={e => setEdRmt({...edRmt,NM_LOC:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'IBGE'}         value={edRmt.CD_IBGE_LOC}   onChange={e => setEdRmt({...edRmt,CD_IBGE_LOC:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'UF'}           value={edRmt.ID_UF}   onChange={e => {}} type="text" error={false} className="w100" disabled/>
          <Input label={'Pais'}         value={edRmt.NM_PAIS}   onChange={e => {}} type="text" error={false} className="w100" disabled/>
        </div>
        <div className="f g1 f-column f1">
          <Select label={'Endereços Destino'} options={listaDestino} value={edDest.ID_OBJUNC} error={false} className="w100"
            onChange={e => setEdDest(listaDestino.find(obj => obj.ID_OBJUNC === e?.value)) } defaultValue={edDest.ID_OBJUNC} searchable />
          <Input label={'Destino'}       value={edDest.NM_LGD}        onChange={e => setEdDest({...edDest,NM_LGD:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'Número'}       value={edDest.NR_LGD}        onChange={e => setEdDest({...edDest,NR_LGD:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'Bairro'}       value={edDest.NM_BRO}        onChange={e => setEdDest({...edDest,NM_BRO:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'Cidade'}       value={edDest.NM_LOC}        onChange={e => setEdDest({...edDest,NM_LOC:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'IBGE'}         value={edDest.CD_IBGE_LOC}   onChange={e => setEdDest({...edDest,CD_IBGE_LOC:e.target.value})} type="text" error={false} className="w100" />
          <Input label={'UF'}           value={edDest.ID_UF}   onChange={e => {}} type="text" error={false} className="w100" disabled/>
          <Input label={'Pais'}         value={edDest.NM_PAIS}   onChange={e => {}} type="text" error={false} className="w100" disabled/>
        </div>
      </div>
      </>
    }
    {/* Rota */}
    {step===2&&<>
      <div className="f g2 w100">
        <div className="f g1 f-column f1">
          <Select label={lang.origem}  options={listaOrigem}  value={edRmt.ID_OBJUNC} defaultValue={edRmt.ID_OBJUNC} onChange={e=>setEdRmt(listaOrigem.find(obj => obj.ID_OBJUNC === e?.value))} searchable
            onInputChange={(v, {action}) => {
              if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, origens: r.results}))
              return v
            }}
          />
          <Select label={lang.destino} options={listaDestino} value={edDest.ID_OBJUNC} defaultValue={edDest.ID_OBJUNC} onChange={e=>setEdDest(listaDestino.find(obj => obj.ID_OBJUNC === e?.value))} searchable

          />

          {App.diretivas.UtilizarTarifaPedagio === 'T'
            ? <Input disabled value={formatCurrency(data.vl_pedagio??0)} label={lang.pedagio} />
            : null
          }
          <Input disabled value={data.qt_km} label={lang.distancia} />
          <Input disabled value={data.hr_duracao} label={lang.duracao} />
        </div>
        <div className="f2 radius overflow-hidden box-shadow">
          {loadingMap?<App.LoadingComponent />:
            <Map key={keyMAP} center={position} zoom={8} style={{ position: "relative", width: "100%", height: 375 }}>
              {posOrigemDestino.inicio.length > 0 && posOrigemDestino.fim.length > 0 && <>
                <Marker position={posOrigemDestino.inicio}><Popup>Inicio da Rota</Popup></Marker>
                <Marker position={posOrigemDestino.fim}><Popup>Final da Rota</Popup></Marker>
                <GeoJSON attribution='&copy; <a href="https://velog.com.br">VELOG</a>'
                  data={{type: "RotaCollection",
                    features: [{type: "Feature", geometry: {type: "LineString", coordinates: rotaOrigemDestino}}],
                  }}
                />
              </>}
              {pedagios.map((value, index) => (
                <Marker icon={iconePedagio} key={`pedagio-${index}`} position={[value.id_lat, value.id_long]} >
                  <Popup>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                      <div style={{ backgroundImage: `url("${value.ds_logo}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", width: "100px", minHeight: "70px", maxHeight: "100px" }} />
                      <span>{value.ds_praca}</span> <span><strong>{formatCurrency(value.vl_tarifa / 6)}</strong></span>
                    </div>
                  </Popup>
                </Marker>
              ))}
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />
            </Map>
          }
        </div>
      </div>
    </>}

    {/* Transportadora */}
    {step===3&&<>
      <Select multi label={lang.transportadora} options={listas.transportadoras} value={data.lst_transp} className="w100"
        onChange={e=>setData({...data, lst_transp: e.map(t=>t.value)})} defaultValue={listas.transportadoras?.filter(t=>!!data.lst_transp.find(v=>v===t.value))} clearable searchable />
        <button onClick={()=>setData({...data, lst_transp: listas.transportadoras.map(t=>t.value)})}>{lang.selecionar_tudo}</button>
    </>}

    {/* Lote */}
    {step===4&&<>
      <div className="w100">
      <Select label={lang.produto} options={listas.produtos} value={data.id_pro} error={!data.id_pro} className="w100"
        onChange={e=>setData({...data, id_pro: e?.value})} defaultValue={data.id_pro} clearable searchable />
      </div>

      <div className="w100 f g1">
        <Input label={lang.inicio}       value={data.dt_inicio}   onChange={e=>setData({...data, dt_inicio: e.target.value})} type="date" error={!data.dt_inicio} className="w1-3" />
        <Input label={lang.validade}     value={data.dt_validade} onChange={e=>setData({...data, dt_validade: e.target.value})} type="date" className="w1-3" />
        <Input label={lang.volume_total} value={formatNumber(data.qt_volume)} onChange={e=>setData({...data, qt_volume: toFloat(e.target.value, 0)})} error={!data.qt_volume} className="w1-3" />
      </div>

      <div className="w100 f g1">
        <Input label={lang.volume_cadencia}  value={formatNumber(data.qt_cadencia)}  onChange={e=>setData({...data, qt_cadencia: toFloat(e.target.value, 0), qt_cota: Math.ceil(toFloat(e.target.value, 0) / data.qt_conversao)})} error={!data.qt_cadencia || data.qt_cadencia<data.qt_conversao} className="w1-3" />
        <Input label={lang.volume_conversao} value={formatNumber(data.qt_conversao)} onChange={e=>setData({...data, qt_conversao: toFloat(e.target.value, 0), qt_cota: Math.ceil(data.qt_cadencia / toFloat(e.target.value, 0))})} error={!data.qt_conversao} className="w1-3" />
        <Input label={lang.cota}             value={formatNumber(data.qt_cota)}      onChange={e=>setData({...data, qt_cota: toFloat(e.target.value, 0), qt_cadencia: data.qt_conversao * toFloat(e.target.value, 0)})} error={!data.qt_cota} className="w1-3" />
      </div>

      <div className="w100 f g1">
        {App.diretivas.UtilizarTarifaPedagio === 'T'
          ? <Input label={lang.pedagio} value={formatCurrency(data.vl_pedagio)}
              onChange={e=>setData({...data, vl_pedagio: toFloat(e.target.value, 2)})}
              className="w1-3"
            />
          : null
        }
        <Input disabled value={data.qt_km??'---'} label={lang.distancia} className="w1-3" />
        <Input disabled value={data.hr_duracao??'---'} label={lang.duracao} className="w1-3" />
      </div>

      <div className="w100 f g1">
        <Input label={lang.valor_frete}     value={formatCurrency(data.vl_frete)}          onChange={e=>setData({...data, vl_frete: toFloat(e.target.value, 2)})} error={!data.vl_frete} className="w50" />
        <Input label={lang.frete_motorista} value={formatCurrency(data.vl_fretemotorista)} onChange={e=>setData({...data, vl_fretemotorista: toFloat(e.target.value, 2)})} className="w50" />
      </div>
    </>}

    {/* Observação */}
    {step === 5 && <>
        <div className="f g1 f-column w100">
          <button title={lang.adicionar} onClick={() => { data.obs = data.obs.filter(o => !!o.ds_obs); data.obs.push({ ds_obs: '' }); setData({ ...data }) }}><icons.MdAdd />{lang.adicionar}</button>
          {data.obs?.map((o, i) =>
            <div key={i} className="f g1 center-v">
              <Checkbox checked={o.in_ticket}
                label={'No ticket'}
                onChange={e => { o.in_ticket = e.target.checked; setData({ ...data }) }}
              />

              <Select label={lang.tipo} className="" options={[
                { value: 'success', label: lang.success, color: 'var(--success)' },
                { value: 'danger', label: lang.danger, color: 'var(--danger)' },
                { value: 'warning', label: lang.warning, color: 'var(--warning)' },
                { value: 'info', label: lang.info, color: 'var(--info)' },
              ]} styles={{ option: (s, { data }) => ({ ...s, color: 'var(--white)', background: data.color }) }}
                onChange={e => { data.obs[i].id_color = e.value; setData({ ...data }) }}
                value={o.id_color} style={{ flexBasis: 150 }}
              />

              <Input value={o.ds_obs} label={lang.observacao} className="f4"
                onChange={e => { data.obs[i].ds_obs = e.target.value; setData({ ...data }) }}
                inputStyle={{ borderLeft: '5px solid var(--' + (o.id_color ?? 'color') + ')' }}
              />

              <button onClick={() => { data.obs.splice(i, 1); setData({ ...data }) }}><icons.BsFillTrashFill /></button>
            </div>
          )}
        </div>
      </>}

      {/* Resumo */}
      {step === 6 && <div className="f g1">
        <div className="f g1 f3 f-column">
          <div className="f g1 w100">
            <Input disabled label={lang.unidade} value={listas.unidades?.find(a => a.VALUE === data.nr_cnpjund)?.LABEL} className='f2' />
            <Input disabled label={lang.operacao} value={{ R: lang.recepcao, E: lang.expedicao }[data.tp_oprprt]} className='f1' />
            <Input disabled label={lang.frete} value={{ C: lang.cif, F: lang.fob }[data.tp_frt]} className='f1' />
          </div>

          <div className="f g1 w100">
            <Input disabled label={lang.cliente} value={listas.parceiros?.find(a => a.VALUE === data.nr_cnpjprc)?.LABEL} className='f1' />
            <Input disabled label={'Tomador'} value={listas.tomadores?.find(a => a.VALUE === data.nr_cnpjtmd)?.LABEL} className='f1' />
          </div>

          <div className="f g1 w100">
            <Input disabled label={lang.remetente} value={listas.remetentes?.find(a => a.VALUE === data.nr_cnpjrmt)?.LABEL} className='f1' />
            <Input disabled label={lang.destinatario} value={listas.destinatarios?.find(a => a.VALUE === data.nr_cnpjdst)?.LABEL} className='f1' />
          </div>

          <div className="f g1 w100">
            <Input disabled label={lang.origem} value={listas.origens?.find(a => a.VALUE === data.nr_cnpjexp)?.LABEL} className='f1' />
            <Input disabled label={lang.destino} value={listasDestinatario?.find(a => a.VALUE === data.nr_cnpjrcb)?.LABEL} className='f1' />
          </div>

          <div className="f g1 w100">
            <Input disabled label={lang.produto} value={listas.produtos?.find(a => a.VALUE === data.id_pro)?.LABEL} className='f2' />
            <Input disabled label={lang.data_inicio} value={App.moment(data.dt_inicio).format('DD/MM/yyyy')} className='f1' />
            <Input disabled label={lang.data_validade} value={data.dt_validade ? App.moment(data.dt_validade).format('DD/MM/yyyy') : '---'} className='f1' />
          </div>

          <div className="f g1 w100">
            <Input disabled label={lang.volume_total} value={formatNumber(data.qt_volume)} className='f1' />
            <Input disabled label={lang.volume_cadencia} value={formatNumber(data.qt_cadencia)} className='f1' />
            <Input disabled label={lang.volume_conversao} value={formatNumber(data.qt_conversao)} className='f1' />
            <Input disabled label={lang.cota} value={formatNumber(data.qt_cota)} className='f1' />
          </div>

          <div className="f g1 w100">
            {App.diretivas.UtilizarTarifaPedagio === 'T'
              ? <Input disabled label={lang.pedagio}
                  value={formatCurrency(data.vl_pedagio)}
                  className='f2'
                />
              : null
            }
            <Input disabled label={lang.distancia} value={data.qt_km} className='f1' />
            <Input disabled label={lang.duracao} value={data.hr_duracao} className='f1' />
          </div>

          <div className="f g1 w100">
            <Input disabled label={lang.frete} value={formatCurrency(data.vl_frete)} className='f1' />
            <Input disabled label={lang.frete_motorista} value={formatCurrency(data.vl_fretemotorista)} className='f1' />
          </div>
        </div>

        <div className="f1"><h5>{lang.transportadoras}</h5>
          <ol>
            {data.lst_transp.map((cnpj, i) =>
              <li key={i}>{listas.transportadoras.find(t => t.value === cnpj).label}</li>
            )}
          </ol>
        </div>
      </div>}
    </Modal>)
}
