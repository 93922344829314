import { useMemo } from "react"

export function Row({ children, className, ...rest }) {

  const class_name = useMemo(() => {
    return [className, 'row'].join(' ')
  }, [className])

  return (
    <div className={class_name} {...rest}>
      {children}
    </div>
  )
}
