import { t } from "../../../../f"
import { TravelRecordCsvPresenter } from "../../presenters/TravelRecordCsvPresenter"
import { saveAudit } from "./saveAudit"

const getApproveAction = ({ selectedRecords: records, onReload, confirm, api, lang }) => {
  const message = t(lang.message, "approve");

  return {
    enabled: records.length > 0 && records.every(({ allowsToApprove }) => allowsToApprove),
    onClick: () => {
      confirm(
        message,
        async () => {
          const status = await saveAudit({ api, records, action: 'approve' })
          onReload()
          return status
        }
      )
    },
  }
}

const getDisapproveAction = ({ selectedRecords: records, onReload, confirm, api, lang }) => {
  const message = t(lang.message, "disapprove");

  return {
    enabled: records.length > 0 && records.every(({ allowsToDisapprove }) => allowsToDisapprove),
    onClick: () => {
      confirm(
        message,
        async () => {
          const status = await saveAudit({ api, records, action: 'disapprove' })
          onReload()
          return status
        }
      )
    },
  }
}

const getInvoicingAction = ({ selectedRecords: records, onClickInvoincing }) => {
  return {
    enabled: records.length > 0 && records.every(({ allowsToInvoice }) => allowsToInvoice),
    onClick: onClickInvoincing,
  }
}

const getInvoicingProcessAction = ({ invoicingUploadId: inputId }) => {
  return () => document.getElementById(inputId).click()
}

const getDownloadTravelRecordsAction = ({ records, lang }) => {
  const csvColumns = [
    'commercialPartner',
    'situation',
    'compliance',
    'ticketId',
    'scheduleOn',
    'deadline',
    'businessArea',
    'situation',
    'contractId',
    'shippingCompanyId',
    'shippingCompany',
    'vehicle',
    'productGroupId',
    'productGroup',
    'invoice',
    'takerId',
    'taker',
    'mainAmount',
    'adittionalAmount',
    'amount',
    'shippingCompanyCteNumber',
    'shippingDateCte',
    'amountCte',
    'nfeWeight',
    'clientNumberCte',
    'serieNumberCte',
    'shippingCompanyCte',
    'cteNumber',
  ]

  const csvRows = records.map((record) => {
    const presenter = new TravelRecordCsvPresenter(record, lang.csv)
    const row = csvColumns.map((column) => presenter.valueOf(column))

    return row
  })

  return {
    enabled: csvRows.length > 0,
    columns:  csvColumns.map((column) => lang.csv[column] ?? column),
    data: csvRows,
    filename: 'registros_de_viagem.csv',
  }
}

const getToggleAction = ({ displayContent, handleChangeDisplay }) => {
  return [
    {
      label: 'Show List',
      enabled: displayContent !== 'list',
      onClick: () => handleChangeDisplay('list'),
    },
    {
      label: 'Show Dashboard',
      enabled: displayContent !== 'summaries',
      onClick: () => handleChangeDisplay('summaries'),
    }
  ];
};

export const getFrameActions = ({
  approvable,
  billable,
  disapprovable,
  invoicingProcessable,
  displayContent,
  handleChangeDisplay,
  ...args
}) => {
  const actions = {}

    if (approvable) actions.approve = getApproveAction(args)

    if(disapprovable) actions.disapprove = getDisapproveAction(args)

    if (billable) actions.invoice = getInvoicingAction(args)

    if(invoicingProcessable) actions.upload = getInvoicingProcessAction(args)

    actions.CSVDownload = getDownloadTravelRecordsAction(args)

    actions.toggleView = getToggleAction({
      displayContent,
      handleChangeDisplay,
    });

    return actions
}
