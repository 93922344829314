import { useContext, useMemo, useState, useCallback, useEffect } from "react";
import moment from "moment";

import { AppContext } from "../../App";
import { Frame } from '../../c'
import { Filters } from "../../c/Filters";
import { t } from "../../f";

import { QuotationTable } from "./components/QuotationTable";
import { fetchQuotationData } from "./useCases/fetchQuotationData";
import { ObservacaoModal } from "./components/Modals/ObservacaoModal";
import { CancelModal } from "./components/Modals/CancelModal";
import { BidModal } from "./components/Modals/BidModal";
import { CreateModal } from "./components/Modals/CreateModal";

export const Bid = ({ setReloader }) => {
  const App = useContext(AppContext);

  const lang = useMemo(() => {
    const { lang: { global, bid } } = App;
    return { ...global, ...bid };
  }, [App]);

  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [editing, setEditing] = useState(false);
  const [products, setProducts] = useState([]);
  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [itineraries, setItineraries] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [displayCreateModal, setDisplayCreateModal] = useState(false);
  const [filters, setFilters] = useState(() => {
    const startDate = moment().subtract(30, 'days').startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();

    return {
      id: '',
      itineraryId: '',
      shippingCompanyId: '',
      product: '',
      situation: '',
      endQuotation: { startDate, endDate },
    }
  });

  const Modal = useMemo(() => {
    if (editing.op === 'observacao') return ObservacaoModal

    if (editing.op === 'cancel') return CancelModal

    if (editing.op === 'lance') return BidModal

    if (editing.op === 'transportadora') return BidModal

    return CancelModal
  }, [editing?.op])

  const filterFields = useMemo(() => {

    const shippingCompanyOptions = shippingCompanies.map(
      ({ NM_TRP: name, NR_CNPJTRP: cnpj, ID_PES: id }) => ({ name, cnpj, id })
    )

    const itineraryOptions = itineraries
      .reduce((result, { code, id, name }) => {
        if (result.find(({ value }) => id === value)) return result
        return [...result, { label: `${code} - ${name}`, value: id }];
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label))

    const fields = {
      endQuotation: ['endQuotation', 'daterange', { label: t(lang.filters, 'endQuotation'), limitation: 31 }],
      id: ['id', 'input', { label: t(lang.filters, 'id') }],
      itineraryId: ['itineraryId', 'select', {
        label: t(lang.filters, 'itinerary'),
        options: itineraryOptions
      }],
      shippingCompanyId: ['shippingCompanyId', 'select', {
        label: t(lang.filters, 'shippingCompanyId'),
        options: shippingCompanyOptions
      }],
      productId: ['productId', 'select', {
        label: t(lang.filters, 'productId'),
        options: products
      }],
      situation: ['situation', 'select', {
        label: t(lang.filters, 'situation'),
        options: [
          { value: 'not_started', label: t(lang.situations, 'not_started') },
          { value: 'started', label: t(lang.situations, 'started') },
          { value: 'approval', label: t(lang.situations, 'approval') },
          { value: 'canceled', label: t(lang.situations, 'canceled') },
          { value: 'finished', label: t(lang.situations, 'finished') },
        ]
      }],
    };

    let allowedFields = App.user.in_uorgadm === 'T'
      ? ['endQuotation', 'id', 'itineraryId', 'shippingCompanyId', 'productId', 'situation']
      : ['endQuotation', 'id', 'itineraryId', 'productId', 'situation'];

    return allowedFields
      .map((field) => fields[field])
      .filter(Boolean)
      .map(([field, kind, settings]) => ({
        key: field,
        field,
        kind,
        label: t(lang, field),
        ...settings,
        value: filters[field]
      }))
  }, [App.user.in_uorgadm, filters, itineraries, lang, products, shippingCompanies])

  const getRows = useCallback(async () => {
    try {
      setLoading(true)
      const fetchedRecords = await fetchQuotationData(App.api, lang, filters)

      setRecords(fetchedRecords)

    } catch (error) {
      setRecords([])
    } finally {
      setLoading(false)
    }
  }, [App.api, filters, lang])

  const handleChangeFilter = useCallback((field) => {
    return (value) => {
      let parsedValue = value;

      if (field === 'endQuotation') {
        const { startDate, endDate } = value
        parsedValue = { startDate: null, endDate: null };

        if (startDate) {
          parsedValue.startDate = moment(startDate).startOf('day').toDate();
        }

        if (endDate) {
          parsedValue.endDate = moment(endDate).endOf('day').toDate();
        }
      }

      setFilters((prevState) => ({ ...prevState, [field]: parsedValue }));
    }
  }, []);

  const handleSubmitFilter = useCallback(async () => {
    await getRows();
  }, [getRows]);

  const handleModal = useCallback((tab) => {
    setEditing(tab)
  }, [])

  const handleCloseModal = useCallback(() => {
    setEditing(false)
  }, [setEditing])

  const handleFinishModal = useCallback(async () => {
    setEditing(false)
    await getRows()
  }, [getRows])

  const handleCloseCreateModal = useCallback(() => {
    setDisplayCreateModal(false);
  }, [])

  const handleFinishCreateModal = useCallback(() => {
    setDisplayCreateModal(false);
    getRows();
  }, [getRows])

  const handleReloadRecords = useCallback(() => {
    getRows();
  }, [getRows])

  useEffect(() => {
    (async () => {
      const responses = await Promise.all([
        App.api('fob::getProdutos'),
        App.api('ListVehicleTypes'),
        App.api('transportadoras::get'),
        App.api('ListItinerary', { routeType: 'R' }),
      ])

      const [
        { results: resultProducts },
        { results: resultVehicleTypes },
        { results: resultShippingCompanies },
        { results: resultItinerary },
      ] = responses

      setProducts(resultProducts)
      setVehicleTypes(resultVehicleTypes)
      setShippingCompanies(resultShippingCompanies)
      setItineraries(resultItinerary)
    })()
  }, [App])

  useEffect(() => {
    if (setReloader) {
      setReloader(() => {
        return () => {
          getRows()
        }
      })
    }
  }, [getRows, setReloader])

  return (
    <>
      <Frame title={"Cotações"}>
        <Filters
          filters={filterFields}
          onChangeFilter={handleChangeFilter}
          onSubmitFilter={handleSubmitFilter}
          loading={loading}
        />
      </Frame>

      <Frame
        flex
        className="quotation"
        title={'Registro de Cotações'}
        loading={loading}
        actions={{
          add: App.user.in_uorgadm === 'T'
            ? () => { setDisplayCreateModal(true) }
            : null,
        }}
      >

        <QuotationTable
          records={records}
          setEditing={handleModal}
          onRelaod={handleReloadRecords}
        />

        {editing && (
          <Modal
            quotation={editing.quotation ?? {}}
            bid={editing.bid ?? {}}
            onClose={handleCloseModal}
            onFinish={handleFinishModal}
            App={App}
          />
        )}

        <CreateModal
          visible={displayCreateModal}
          lang={App.lang.bid.createModal}
          onClose={handleCloseCreateModal}
          onFinish={handleFinishCreateModal}
          products={products}
          vehicleTypes={vehicleTypes}
          shippingCompanies={shippingCompanies}
          itineraries={itineraries}
        />
      </Frame>
    </>
  );
};
