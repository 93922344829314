import { useMemo } from "react"
import './styles.sass'

export function IconButton({
  children = null,
  className = '',
  icon = null,
  selected = false,
  size = 36,
  style = {},
  disabled = false,
  ...rest
}) {

  const buttonClassName = useMemo(() => {
    return [
      'iconButton',
      disabled ? 'disabled' : '',
      className
    ].join(' ')
  }, [className, disabled])

  const buttonStyle = useMemo(() => ({
    maxWidth: size,
    maxHeight: size,
    fontSize: parseInt(size / 2),
    ...style
  }), [size, style])

  return (
    <button
      className={buttonClassName}
      style={buttonStyle}
      disabled={disabled}
      {...rest}
    >
      {children} {!!icon && icon({ size })}
    </button>
  )
}
