import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { getFilterWithApiOptions, getFilterWithoutApiOptions } from "./useCases/getFilters";

import { Frame, Tab, TabList, TabPanel, Tabs } from "../../c";
import { Filters } from "../../c/Filters";
import { AppContext } from "../../App";
import { PaymentRecords } from "./PaymentRecords";
import { TravelRecords } from "./TravelRecords";
import { fetchData } from "./useCases/fetchData";
import { fetchSummaries } from "./useCases/fetchSummaries";
import { InvoiceRecords } from "./InvoiceRecords";

import './styles.sass';

const TABS = [
  ['travel_records', 0],
  ['payment_records', 0],
  ['invoice_records', 0],
]

export const FreightControllership = ({ setReloader }) => {
  const App = useContext(AppContext)
  const { user } = App

  const lang = useMemo(() => {
    const { lang: { global, freight_controllership } } = App
    return { ...global, ...freight_controllership }
  }, [App])

  const [tabs, setTabs] = useState(TABS);
  const [loading, setLoading] = useState(false);
  const [recordsFetched, setRecordsFetched] = useState(false)
  const [records, setRecords] = useState([])
  const [filters, setFilters] = useState(
    getFilterWithoutApiOptions({ api: App.api, lang, user })
  )
  const [selectedTab, setSelectedTab] = useState(
    Number(localStorage.getItem('freight-controllership-tab') ?? 0)
  )

  const [summaries, setSummaries] = useState([]);

  const Records = useMemo(() => {
    if(selectedTab === 0) return TravelRecords

    if(selectedTab === 1) return PaymentRecords

    return InvoiceRecords
  }, [selectedTab])

  const handleChangeFilter = useCallback((field) => {
    return (value) => {
      setLoading(true)

      setFilters((prevState) => {
        return prevState.map(item => {
          if (item.field === field) return { ...item, value };

          return item;
        });
      })

      setLoading(false)
    }
  }, [])

  const handleLoadFilters = useCallback(async () => {
    const newFilters = await getFilterWithApiOptions({ api: App.api, lang, user })

    setFilters((prevState) => {
      return newFilters.map((filter) => {
        const prevFilter = prevState.find(({field}) => filter.field === field)
        return {...filter, value: prevFilter?.value ?? filter.value }
      })
    })
  }, [App.api, lang, user])

  const handleSubmitFilter = useCallback((tab) => {
    return async () => {
      setLoading(true)
      try{
        const { records: newRecords, tabs: newTabs } = await fetchData({
          api: App.api,
          filters,
          user,
          tab
        })
        const response = await fetchSummaries({
          api: App.api,
          filters,
          user
        });
        const newSummaries = response.records?.results || [];

        setRecords(newRecords)
        setTabs(newTabs)
        setSummaries(newSummaries)
        setRecordsFetched(true)
      } finally{
        setLoading(false)
      }
    }
  }, [App.api, filters, user])

  const handleSelectedTab = useCallback((tab) => {
    setLoading(true)

    setSelectedTab(tab)
    localStorage.setItem('freight-controllership-tab', tab)

    if(recordsFetched) {
      handleSubmitFilter(tab)()
    } else {
      setLoading(false)
    }
  }, [handleSubmitFilter, recordsFetched])

  const handleChangeRecords = useCallback((newRecords) => {
    setRecords(newRecords)
  }, [])

  useEffect(() => {
    handleLoadFilters()
  }, [handleLoadFilters])

  useEffect(() => {
    if(setReloader) {
      setReloader(() => {
        return () => {
          const rawFilters = getFilterWithoutApiOptions({ api: App.api, lang, user })

          setFilters(rawFilters)
          handleLoadFilters()
          setTabs(TABS)
          setRecordsFetched(false)
          setRecords([])
        }
      })
    }
  }, [App.api, handleLoadFilters, lang, setReloader, user])

  const countTotalsDocuments = tabs[0][1]

  return (
    <div className="freight-controllership">
      <Frame title={lang.title}>
        <div className="f f-column g1">
          <Filters
            filters={filters}
            loading={loading}
            onChangeFilter={handleChangeFilter}
            onSubmitFilter={handleSubmitFilter(selectedTab)}
          />
        </div>
        </Frame>

        <Frame >
        <div className="f f-column g1">
          <Tabs
            selectedIndex={selectedTab}
            onSelect={handleSelectedTab}
          >
            <div className="f f-column g1 ">
              <TabList>
                {tabs.map(([tabName, count = 0]) => (
                  <Tab key={tabName}>
                    {lang[tabName]?.tab ?? tabName}
                    <span className="destaque info">
                      <b>{count}</b>
                    </span>
                  </Tab>
                ))}
              </TabList>
            </div>

            {tabs.map(([tab]) => <TabPanel key={tab}></TabPanel>)}
          </Tabs>

        </div>
      </Frame>
      <Records
        lang={lang.travel_records}
        loading={loading}
        onChangeRecords={handleChangeRecords}
        onFetchRecords={handleSubmitFilter(selectedTab)}
        records={loading ? [] : records}
        summaries={loading ? [] : summaries}
        countTotalsDocuments={countTotalsDocuments}
        user={user}
        filters={filters}
      />
    </div>
  );
}
