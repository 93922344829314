import React, { useRef, useEffect, useState } from "react";
import { Canvas, FabricImage } from "fabric";

const ImageEditor = ({ imageUrl, imageBase64, onSave }) => {
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);

    useEffect(() => {
        if (!canvasRef.current) return;

        if (canvas) {
            canvas.dispose();
        }

        const fabricCanvas = new Canvas(canvasRef.current, {
            backgroundColor: "white",
        });

        setCanvas(fabricCanvas);

        const loadImage = (source) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = "anonymous";
                img.onload = () => {
                    
                    // determina as dimensões da imagem e ajusta o canvas
                    const width = img.width;
                    const height = img.height;
                    const adjustedWidth = width + 200;
                    const adjustedHeight = height + 300;

                    fabricCanvas.setWidth(adjustedWidth);
                    fabricCanvas.setHeight(adjustedHeight);

                    const fabricImg = new FabricImage(img, {
                        left: 50,
                        top: 50,
                    });

                    fabricCanvas.add(fabricImg);
                    fabricCanvas.getObjects().forEach((obj) => obj.set('borderColor', 'gray'));
                    fabricCanvas.getObjects().forEach((obj) => obj.set('cornerColor', 'gray'));
                    fabricCanvas.renderAll();

                    resolve();
                };
                img.onerror = (error) =>
                    reject(new Error(error.message));
                img.src = source;
            });
        };

        const source = imageBase64 || imageUrl;

        if (source) {
            loadImage(source).catch(console.error);
        }

    }, [imageUrl, imageBase64]);

    const handleZoomIn = () => {
        if (canvas) {
            const zoom = canvas.getZoom();
            canvas.setZoom(zoom * 1.1);
            canvas.renderAll();
        }
    };

    const handleZoomOut = () => {
        if (canvas) {
            const zoom = canvas.getZoom();
            canvas.setZoom(zoom * 0.9);
            canvas.renderAll();
        }
    };

    const handleRotateLeft = () => {
        if (canvas) {

            canvas.discardActiveObject();
            canvas.getObjects().forEach((obj) => obj.set('borderColor', 'transparent'));
            canvas.getObjects().forEach((obj) => obj.set('cornerColor', 'transparent'));
            canvas.renderAll();

            const image = canvas.getObjects("image")[0];
            if (image) {
                image.rotate(image.angle - 15);
                canvas.renderAll();
            }

            canvas.getObjects().forEach((obj) => obj.set('borderColor', 'gray'));
            canvas.getObjects().forEach((obj) => obj.set('cornerColor', 'gray'));
            canvas.renderAll();
        }
    };

    const handleRotateRight = () => {
        if (canvas) {

            canvas.discardActiveObject();
            canvas.getObjects().forEach((obj) => obj.set('borderColor', 'transparent'));
            canvas.getObjects().forEach((obj) => obj.set('cornerColor', 'transparent'));
            canvas.renderAll();

            const image = canvas.getObjects("image")[0];
            if (image) {
                image.rotate(image.angle + 15);
                canvas.renderAll();
            }

            canvas.getObjects().forEach((obj) => obj.set('borderColor', 'gray'));
            canvas.getObjects().forEach((obj) => obj.set('cornerColor', 'gray'));
            canvas.renderAll();
        }
    };


    const handleSave = () => {
        if (canvas) {
            canvas.discardActiveObject();
            canvas.getObjects().forEach((obj) => obj.set('borderColor', 'transparent'));
            canvas.getObjects().forEach((obj) => obj.set('cornerColor', 'transparent'));
            canvas.renderAll();

            const image = canvas.getObjects("image")[0];
            if (image) {

                const boundingRect = image.getBoundingRect(true);

                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = boundingRect.width;
                tempCanvas.height = boundingRect.height;
                const tempContext = tempCanvas.getContext('2d');

                tempContext.drawImage(
                    canvas.getElement(),
                    -boundingRect.left,
                    -boundingRect.top
                );

                const dataURL = tempCanvas.toDataURL('image/png');

                if (onSave) {
                    onSave(dataURL);
                }
            }

            canvas.getObjects().forEach((obj) => obj.set('borderColor', 'gray'));
            canvas.getObjects().forEach((obj) => obj.set('cornerColor', 'gray'));
            canvas.renderAll();
        }
    };

    return (
        <div>
            <div style={{ width: "100%", display: "flex", gap: "10px", marginTop: "10px", marginBottom: "10px", alignContent:'center', justifyItems:'center' }}>
                <button style={{height:'34px'}} onClick={handleRotateLeft}>Girar Imagem para a Esquerda</button>
                <button style={{height:'34px'}} onClick={handleRotateRight}>Girar Imagem para a Direita</button>
                <button style={{height:'34px'}} onClick={handleSave}>Salvar Imagem Editada</button>
            </div>
            <canvas
                ref={canvasRef}
                style={{ border: "1px dashed gray" }}
            />
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <button style={{height:'34px'}} onClick={handleRotateLeft}>Girar Imagem para a Esquerda</button>
                <button style={{height:'34px'}} onClick={handleRotateRight}>Girar Imagem para a Direita</button>
                <button style={{height:'34px'}} onClick={handleSave}>Salvar Imagem Editada</button>
            </div>
        </div>
    );
};

export default ImageEditor;